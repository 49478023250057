import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

function shouldFetchNumbers(state: ReduxState) {
	return !state.numbers.fetching && !state.numbers.fetched;
}

function getAlias(endpoints: { id: string; alias: string }[], endpointId: string) {
	const newEndpoint = endpoints.find(endpoint => endpoint.id === endpointId);

	if (!newEndpoint) {
		return null;
	}

	return newEndpoint.alias;
}

function getEndpointAlias(state: ReduxState, endpointId: string | null) {
	if (!endpointId) {
		return null;
	}

	switch (endpointId.charAt(0)) {
		case 'p':
			return getAlias(state.phonelines.items, endpointId);
		case 'g':
			return getAlias(state.groups.items, endpointId);
		case 'f':
			return getAlias(state.faxlines.items, endpointId);
		default:
			return null;
	}
}

export const fetchAccessibleNumbers = createAction(
	'NUMBERS_FETCH_ACCESSIBLE',
	(force?: boolean) => ({
		promise: () => {
			return Promise.all([api.getAllNumbers(), api.getAcds().catch(() => ({ items: [] }))]);
		},
		shouldFetch: (state: ReduxState) => force || shouldFetchNumbers(state),
	})
);

export const forceFetchAccessibleNumbers = () => fetchAccessibleNumbers(true);

export const setNumberSettings =
	(
		numberId: string,
		endpointId: string | null = null,
		releaseForMnp = false,
		isQuickDial = false
	) =>
	(
		dispatch: Dispatch<{
			type: 'NUMBER_SET_SETTINGS';
			payload: {
				promise: () => Promise<void>;
				data: {
					numberId: string;
					endpointId: string | null;
					releaseForMnp: boolean;
					endpointAlias: string | null;
				};
			};
		}>,
		getState: () => ReduxState
	) =>
		dispatch(
			createAction('NUMBER_SET_SETTINGS', () => ({
				promise: () => api.setNumberSettings(numberId, endpointId, releaseForMnp, isQuickDial),
				data: {
					numberId,
					endpointId,
					releaseForMnp,
					endpointAlias: getEndpointAlias(getState(), endpointId),
				},
			}))()
		);

export const setQuickdialNumber = createAction(
	'QUICKDIAL_SET',
	(userId: string, numberId: string, number: string) => ({
		promise: () => api.setQuickDialNumber(userId, numberId, number),
		data: {
			number,
			numberId,
		},
	})
);

export const createQuickDialNumber = createAction(
	'QUICKDIAL_CREATE',
	(userId: string, number: string) => ({
		promise: () => api.createQuickDialNumber(userId, number),
		data: {
			userId,
			number,
		},
	})
);

export const deleteQuickDialNumber = createAction('QUICKDIAL_DELETE', (numberId: string) => ({
	promise: api.deleteQuickDialNumber(numberId),
	data: {
		numberId,
	},
}));
