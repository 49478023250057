import { createAction } from '../..';
import api from '../../../api';
import {
	ApiCreateAddressRequest,
	ApiEditAddressRequest,
	ApiMoveAddressRequest,
} from '../../../api/types/addresses';
import { ReduxState } from '../../types';

export type EditRequest = ApiEditAddressRequest;
export type MoveRequest = ApiMoveAddressRequest;
export type CreateRequest = ApiCreateAddressRequest;

export const fetchAddresses = createAction('NEW_ADDRESSES_FETCH', () => ({
	promise: () => api.getAddresses(),
	shouldFetch: (state: ReduxState) => !state.addresses.fetched && !state.addresses.fetching,
}));

export const forceFetchAddresses = createAction('NEW_ADDRESSES_FETCH', () => ({
	promise: () => api.getAddresses(),
}));

export const createAddress = createAction('NEW_ADDRESS_CREATE', (request: CreateRequest) => ({
	promise: () => api.createAddress(request),
	restrictions: {
		restrictions: [
			`CAN_CREATE_ADDRESS`,
			'CAN_EDIT_ADDRESS',
			'CAN_MOVE_ADDRESS',
			'CAN_DELETE_ADDRESS',
			'CAN_USE_PHONEBOOK_ENTRY',
		],
	},
}));

export const editAddress = createAction('NEW_ADDRESS_EDIT', (id: number, request: EditRequest) => ({
	promise: () => api.editAddress(id, request),
	restrictions: {
		restrictions: [
			`CAN_CREATE_ADDRESS`,
			'CAN_EDIT_ADDRESS',
			'CAN_MOVE_ADDRESS',
			'CAN_DELETE_ADDRESS',
			'CAN_USE_PHONEBOOK_ENTRY',
		],
	},
}));

export const moveAddress = createAction('NEW_ADDRESS_MOVE', (id: number, request: MoveRequest) => ({
	promise: async () => {
		const address = await api.moveAddress(id, request);

		if ('relocation' in address && !address.relocation) {
			return {
				address,
				numbers: (await api.getAllNumbers()).items,
			};
		}

		return {
			address,
			numbers: null,
		};
	},
	data: {
		sourceId: id,
	},
	restrictions: {
		restrictions: [
			`CAN_CREATE_ADDRESS`,
			'CAN_EDIT_ADDRESS',
			'CAN_MOVE_ADDRESS',
			'CAN_DELETE_ADDRESS',
			'CAN_USE_PHONEBOOK_ENTRY',
		],
	},
}));

export const deleteAddress = createAction(
	'NEW_ADDRESS_DELETE',
	(id: number, emergencyAddressId?: number) => ({
		promise: () => api.deleteAddress(id, { emergencyAddress: emergencyAddressId }),
		restrictions: {
			restrictions: [
				`CAN_CREATE_ADDRESS`,
				'CAN_EDIT_ADDRESS',
				'CAN_MOVE_ADDRESS',
				'CAN_DELETE_ADDRESS',
				'CAN_USE_PHONEBOOK_ENTRY',
			],
		},
		data: {
			addressId: id,
			emergencyAddressId,
		},
	})
);

export const fetchBusinessTypes = createAction('BUSINESS_TYPES_FETCH', () => ({
	promise: () => api.getBusinessTypes(),
	shouldFetch: (state: ReduxState) =>
		!state.addresses.fetchingBusinessTypes && !state.addresses.fetchedBusinessTypes,
}));
