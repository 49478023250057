import { handleActions } from '../..';
import * as actions from './actions';
import { IvrState } from './types';

const initialState: IvrState = {
	fetched: false,
	fetching: false,
	items: [],
};

export default handleActions<IvrState, PossibleActions<typeof actions>>(
	{
		IVRS_FETCH_PENDING: state => ({
			...state,
			fetching: true,
		}),
		IVRS_FETCH_SUCCESS: (state, { payload }) => ({
			...state,
			fetched: true,
			fetching: false,
			items: payload,
		}),
	},
	initialState
);
