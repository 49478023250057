import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

const shouldFetchIvrs = (state: ReduxState) => !state.ivrs.fetched && !state.ivrs.fetching;

export const fetchIvrs = createAction('IVRS_FETCH', () => ({
	promise: () => api.getIvrs(),
	shouldFetch: shouldFetchIvrs,
	restrictions: {
		restrictions: ['CAN_CREATE_IVR'],
	},
}));

export const forceFetchIvrs = createAction('IVRS_FETCH', () => ({
	promise: () => api.getIvrs(),
	restrictions: {
		restrictions: ['CAN_CREATE_IVR'],
	},
}));
