import {
	ConditionalForwardingSet,
	ForwardingSet,
	TimeBasedForwardingSet,
	UnconditionalForwardingSet,
} from './types';
import { ApiTimeBasedSet } from '../../../api/types/timeBasedSets';
import {
	ApiConditionalForwardings,
	ApiTimeBasedForwardings,
	ApiUnconditionalForwardings,
} from '../../../api/types/timeBasedForwardings';

export function convertForwardingSetToApiTimeBasedSet(
	forwardingSet: UnconditionalForwardingSet | ConditionalForwardingSet
) {
	return {
		id: forwardingSet.id,
		name: forwardingSet.name,
		activeTimes: forwardingSet.activeTimes,
		isUserDefined: forwardingSet.isUserDefined,
		priority: forwardingSet.priority,
	};
}

export function convertForwardingSetToApiTimeBasedForwardings(
	forwarding: ForwardingSet
): ApiTimeBasedForwardings {
	if (forwarding.type === 'unconditional' || forwarding.type === 'holiday') {
		return {
			forwardings: {
				UNCONDITIONAL: forwarding.unconditional,
			},
		};
	}

	return {
		forwardings: {
			UNREACHABLE: forwarding.online,
			BUSY: forwarding.busy,
			OFFLINE: forwarding.offline,
		},
	};
}

const isApiUnconditionalForwardings = (
	forwardings: ApiUnconditionalForwardings | ApiConditionalForwardings
): forwardings is ApiUnconditionalForwardings => 'UNCONDITIONAL' in forwardings;

export function convertTimeBasedForwardingsSet(
	timeBasedSet: ApiTimeBasedSet,
	timeBasedForwardings: ApiTimeBasedForwardings | null
): TimeBasedForwardingSet {
	if (!timeBasedForwardings) {
		return {
			id: timeBasedSet.id,
			name: timeBasedSet.name || '',
			activeTimes: timeBasedSet.activeTimes,
			isUserDefined: timeBasedSet.isUserDefined,
			online: [],
			busy: [],
			offline: [],
			priority: timeBasedSet.priority,
			type: 'conditional',
		};
	}

	if (isApiUnconditionalForwardings(timeBasedForwardings.forwardings)) {
		return {
			id: timeBasedSet.id,
			name: timeBasedSet.name || '',
			activeTimes: timeBasedSet.activeTimes,
			isUserDefined: timeBasedSet.isUserDefined,
			unconditional: timeBasedForwardings.forwardings.UNCONDITIONAL,
			priority: timeBasedSet.priority,
			type: 'unconditional',
		};
	}

	return {
		id: timeBasedSet.id,
		name: timeBasedSet.name || '',
		activeTimes: timeBasedSet.activeTimes,
		isUserDefined: timeBasedSet.isUserDefined,
		online: timeBasedForwardings.forwardings.UNREACHABLE,
		busy: timeBasedForwardings.forwardings.BUSY,
		offline: timeBasedForwardings.forwardings.OFFLINE,
		priority: timeBasedSet.priority,
		type: 'conditional',
	};
}

/*
 * The API does not allow saving a conditional forwarding set with a zero-delay
 * on the first active forwarding step due to technical reasons.
 * It also does not allow unconditional sets with a first step with a non-zero delay.
 *
 * This method fixes those edgecases.
 */
export function fixDelay(forwarding: ForwardingSet): ForwardingSet {
	if (forwarding.type === 'unconditional' || forwarding.type === 'holiday') {
		return {
			...forwarding,
			unconditional: forwarding.unconditional.map((step, index) =>
				index === 0 ? { ...step, delay: 0 } : step
			),
		};
	}

	if (forwarding.online[0] && forwarding.online[0].delay === 0) {
		return {
			...forwarding,
			online: forwarding.online.map((step, index) => (index === 0 ? { ...step, delay: 5 } : step)),
		};
	}

	return forwarding;
}
